import crudMutations from "@/store/templates/crud/mutations";

export default {
  setFirstName(state, firstName) {
    state.model.firstName = firstName;
  },
  setLastName(state, lastName) {
    state.model.lastName = lastName;
  },
  setEmail(state, email) {
    state.model.user.email = email;
  },
  setRoles(state, roles) {
    state.model.user.roles = roles;
  },
  setPermissions(state, permissions) {
    state.model.user.permissions = permissions;
  },
  setColor(state, color) {
    state.model.color = color;
  },
  ...crudMutations
};
